// CfgCodePopup

<template>
    <v-dialog v-model="dialog" max-width="800px" height="750px" persistent scrollable>
        <v-card min-height="600px" max-height="600px">
            <v-card-title>
            </v-card-title>
            <v-card-text ref="st" class="black">
                <template v-for="(item, idx) in items">
                <div :key="idx" class="d-block px-2 ma-0 black grey--text caption">
                    <v-icon small color="blue">mdi-arrow-collapse-right</v-icon>{{ item.command }}
                </div>
                    <template v-for="(msg, index) in item.results">
                    <div :key="idx+'.'+index" class="d-block px-2 ma-0 black caption"
                        :class="msg.msgtype == 'info' ? 'teal--text' : (msg.msgtype == 'error' ? 'red--text' : 'orange--text')"
                        v-html='msg.msgtext'>
                    </div>
                    </template>
                </template>
            </v-card-text>
            <v-card-actions>
                <v-btn :disabled="tasking" @click="onAnalyse">Analyse</v-btn>
                <v-btn :disabled="tasking" @click="onEvolve">Evolve</v-btn>
                <v-btn :disabled="tasking" @click="onUpdate">Update</v-btn>
                <v-spacer />
                <v-progress-circular :value="progress"></v-progress-circular>
                <v-spacer />
                <v-btn :disabled="tasking" @click="onClear">Clear</v-btn>
                <v-btn :disabled="tasking" color="primary" @click="onClose">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>          
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	name: 'CfgCodePopup',
	components: {},
    props: {
        model: {
            type: Object,
            default () {
                return {}
            }
        },
    },
	data: () => {
		return {
            dialog: false,
            ready: false,
            tasking: false,
            tasks: [],
            taskcount: null,
            readyWatcher: null,
            progress: 0,
            text: 'line 1\n:)\nline 2\n:)\n:)',
        }
	},
	computed: {
		...mapGetters({
			items: 'cfgtables/getCfgCommands',
		}),
        htmltext: function () {
            //str.replace(/hello/g, 'hi');
            //str.replace(new RegExp('hello', 'g'), 'hi');
            console.log(this.text.replace(new RegExp('\\n', 'g'), '<br>'))
            return this.text.replace(new RegExp('\\n', 'g'), '<br>')
        },

    },
	mounted() {},
    created() {
        this.readyWatcher = this.$watch('ready', (newVal, oldVal) => {
            console.log('ready changed to: ', newVal)
            if(this.ready == true) {
                this.ready = false
                this.executeTask()
            }
         });
        //}, { deep: true });
    },
	methods: {
        onClose() {
            this.dialog = false
            this.$emit('codepopup-closed')
        },
        onAnalyse() {
            //var queue = [];queue.push(2);queue.push(5);var i = queue.shift();
            this.tasking = true
            this.tasks.push('check_columns', 'analyse_change', 'analyse_tables', 'analyse_columns')
            this.taskcount = this.tasks.length
            this.progress = Math.ceil(1 / (this.taskcount + 1) * 100)
            this.ready = true
        },
        onEvolve() {
            this.tasking = true
            this.tasks.push('check_columns')
            this.tasks.push('missing_tables', 'modified_columns', 'missing_columns', 'missing_keys', 'missing_constraints')
            this.taskcount = this.tasks.length
            this.progress = Math.ceil(1 / (this.taskcount + 1) * 100)
            this.ready = true
        },
        onUpdate() {
            this.tasking = true
            this.tasks.push('check_columns', 'update_reftables', 'update_lists', 'update_forms', 'update_lookup', 'fill_empty')
            this.taskcount = this.tasks.length
            this.progress = Math.ceil(1 / (this.taskcount + 1) * 100)
            this.ready = true
        },
        onClear() {
            this.$store.commit('cfgtables/resetCfgCommands')
        },
        executeTask() {
            const task = this.tasks.shift()
            console.log('exec: ', task)
		    const data = { command: task }
            this.$store.dispatch('cfgtables/sendCfgExec', data)
            .then((response) => {
                console.log('finished: ', task)
                this.progress = Math.ceil((this.taskcount - this.tasks.length + 1) / (this.taskcount + 1) * 100)
                console.log(response)
                if(this.tasks.length) {
                    this.ready = true
                } else {
                    this.tasking = false
                }
            })
            .catch((error) => {
                console.log('sendCfgExec->error')
                console.log(error)
                this.tasking = false
                this.tasks = []
            });
            /*setTimeout(() => {
                console.log('finished: ', task)
                this.progress = Math.ceil((this.taskcount - this.tasks.length + 1) / (this.taskcount + 1) * 100)
                if(this.tasks.length) {
                    this.ready = true
                } else {
                    this.tasking = false
                }
            }, 3000)*/
        },
    },
	watch: {
        model: {
            handler(val) {
                if (!val) return;
                this.dialog = val.open
            },
            deep: true,
        },
        items: {
            handler(val) {
                console.log(val)
                if (!val) return;
                //this.$vuetify.goTo(this.$refs.sc, {offset: -9999})
                //this.$refs.sc.scrollTop = Math.floor(this.$refs.st.offsetHeight)
                //setTimeout(() => {
                this.$nextTick(() => {
                    this.$refs.st.scrollTop = 9999//Math.floor(this.$refs.sc.offsetHeight)
                });
                //}, 2000)
                //this.$refs.st.scrollTop = this.$refs.sc.lastElementChild.offsetTop;
                console.log('scrolled')
            },
            deep: true,
        },
    },
};
</script>
<style>
</style>