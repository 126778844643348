// cfgtables

<template>
  	<v-container fluid>
		<v-app-bar dense>
			<v-spacer />
			<v-btn small @click="onCodePopup">Evaluate</v-btn>
		</v-app-bar>
		<v-row class="mt-0 mb-0 pt-1 pb-0">
			<v-col cols="12" sm="4" md="3" lg="2" v-for="(table, tindex) in items" :key="'t' + tindex">
				<v-card>
					<v-toolbar dense flat color="blue-grey darken-2">
						<v-toolbar-title dense class="body-2 font-weight-light ml-n1">{{table.table_name}}</v-toolbar-title>
						<v-spacer/>
						<v-btn color="blue-grey darken-3 mr-n2" fab x-small @click="onEditItem(table.id)"><v-icon small>mdi-pencil</v-icon></v-btn>
					</v-toolbar>
					<v-card-text style="overflow-y: auto; height:80px">
						<template v-for="(column, cindex) in table.cfgcolumns">
							<v-row :key="'c' + cindex" v-if="column.reftable" class="px-2">
							<v-icon small>mdi-key-variant</v-icon><v-spacer />{{column.reftable}}
							</v-row>
							<v-row :key="cindex" v-if="!!column.error" class="px-2 red--text">
							<v-icon small color="red">mdi-alert</v-icon><v-spacer />{{column.column_name}}
							</v-row>
							<v-row :key="cindex" v-else-if="column.changed" class="px-2 orange--text">
							<v-icon small color="orange">mdi-alert-decagram</v-icon><v-spacer />{{column.column_name}}
							</v-row>
						</template>
					</v-card-text>
					<v-card-actions></v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<CfgTablePopup :model="popup" @popup-cancelled="onPopupCancelled()" @popup-submitted="onPopupSubmitted()" />
		<CfgCodePopup :model="code" @codepopup-closed="onCodePopupClosed()"/>
		<v-btn fab bottom right color="pink" fixed @click="onEditItem(-1)"><v-icon>add</v-icon></v-btn> 
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
	    <v-snackbar v-model="snack.active" :color="snack.type"> {{ snack.text }}
      		<v-btn text dark @click="snack.active = false">Ok</v-btn>
    	</v-snackbar>
	</v-container>
</template>

<script>
import CfgTablePopup from './CfgTablePopup'
import CfgCodePopup from './CfgCodePopup'
import { mapGetters } from 'vuex'
export default {
	name: 'cfgtables',
	components: {
		CfgTablePopup,
		CfgCodePopup,
	},
	data() {
		return {
			loading: false,
			popup: { open: false },
			code: { open: false },
			snack: { active: false, type: 'success', text: 'saved',	},
		}
	},
	computed: {
		...mapGetters({
			items: 'cfgtables/getCfgTables',
		}),
	},
	mounted() {
		this.fetchData ()
	},
	methods: {
		fetchData () {
			this.loading = true
			let self = this
			this.$store.dispatch('cfgtables/getCfgTables')
			.then((response) => {
				self.loading = false
			})
			.catch((error) => {
				console.log(error)
				self.loading = false
			});
		},
		onEditItem (id = null) {
			if(id >= 0) {
				this.popup.item = Object.assign({}, this.items.find(x => x.id === id))
				//this.item = Object.assign({}, item)
			} else {
				this.popup.item = {}
			}
			this.popup.open = true
			console.log(this.popup)
		},
		onPopupCancelled () {
			this.popup.open = false
			this.snack = {active: true, type: 'warning', text: 'cancelled'}
		},
		onPopupSubmitted() {
			this.popup.open = false
			this.fetchData ()
			this.snack = {active: true, type: 'success', text: 'saved'}
		},
		onCodePopup() {
			this.code.open = true
		},
		onCodePopupClosed () {
			this.code.open = false
			this.fetchData ()
			this.snack = {active: true, type: 'success', text: 'code closed'}
		},
	},
	watch: {},
};
</script>
<style>
</style>